import React from 'react'
import { useParams } from 'react-router-dom'
import v1 from '../../assets/video/verifyEmail.mp4'
const EmailVerifyPage = () => {
  const { iEmailVerifyId } = useParams()
  console.log('iEmailVerifyId', iEmailVerifyId)
  return (
    <div className='flex flex-col items-center justify-center min-h-screen bg-black text-white'>
      <div className='flex justify-center items-center w-full h-auto'>
        <video className='w-4/5 max-w-lg rounded-lg animate-fade-in' autoPlay loop playsInline muted>
          <source src={v1} type='video/webm' />
          <h4>Sweephy</h4>
        </video>
      </div>
      <h1 className='mt-5 text-2xl text-center font-extralight animate-fade-in'>Thank you for the Verification</h1>
    </div>
  )
}

export default EmailVerifyPage
