const allRoutes = {
    login: '/login',
    dashboard: '/dashboard',
    forgotPassword: '/forgot-password',
    checkEmail: '/check-email',
    resetPassword: '/reset-password',
    resetSuccessfully: '/reset-successfully',
    elements: '/elements',
    userList: '/user-list',
    user: '/user',
    event: '/event',
    signUp:'/signup',
    onBoarding:'/onboarding',
    userProfile:'/dashboard/profile-section',
    chatBox:'/dashboard/default/chatbox'
  }
  export default allRoutes