import { Crypt } from 'hybrid-crypto-js'
import { PUBLIC_KEY } from '../constants/constant'


export const encryption = (data) => {
    const crypt = new Crypt()
    const encrypted = crypt.encrypt(PUBLIC_KEY, data)
    return encrypted.toString()
  }


 // utils.js
export const setUser = (data) => {
  setItem('jwtToken', data?.oData?.sToken?.sToken); 
  setItem('userData', JSON.stringify(data.user));
};

  export const isUserLoggedIn = () => {
    return getItem('jwtToken')
  }

  export const userLogOut = () => {
    // userData = null
    localStorage.removeItem('jwtToken')
  }


  //local storage utils
  
  // localStorage.js or localStorageUtil.js

// Function to set items in localStorage
export const setItem = (key, value) => {
  try {
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(key, serializedValue);
  } catch (error) {
    console.error('Error setting item in localStorage', error);
  }
};



// Function to get items from localStorage
export const getItem = (key) => {
  try {
    const serializedValue = localStorage.getItem(key);
    if (serializedValue === null) {
      return null;
    }
    return JSON.parse(serializedValue);
  } catch (error) {
    console.error('Error getting item from localStorage', error);
    return null;
  }
};

// Function to remove an item from localStorage
export const removeItem = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error('Error removing item from localStorage', error);
  }
};

// Function to clear all items from localStorage
export const clearAll = () => {
  try {
    localStorage.clear();
  } catch (error) {
    console.error('Error clearing localStorage', error);
  }
};

