import React, { useState, useEffect } from 'react'
import fetchingImg from '../../assets/Images/fetchingImg.png'
import { AiOutlineSend } from 'react-icons/ai'
import CopyRight from '../copy-right/CopyRight'

function LoadingYourDashboard() {
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress(prev => {
        if (prev >= 99) {
          clearInterval(interval)
          return 99
        }
        const randomIncrement = Math.random() * 1.5 + 2.0
        return Math.min(prev + randomIncrement, 99)
      })
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return (
    <section className='bg-primary-500 min-h-screen w-full flex flex-col items-center justify-evenly'>
      <div className='flex flex-col items-center justify-center gap-7 mt-auto'>
        <div>
          <h2 className='text-5xl text-white font-semibold'>Generating your dashboard</h2>
        </div>
        <div className='flex flex-col items-center gap-7'>
          <div className='flex justify-center items-center'>
            <div className='loader'>
              <div className='dot'></div>
              <div className='dot'></div>
              <div className='dot'></div>
            </div>
          </div>
          <div>
            <img src={fetchingImg} alt='fetching-data-img' />
          </div>
        </div>
        <div className='text-white flex gap-7'>
          <div className='text-center'>
            <h2 className='text-5xl font-semibold'>{Math.round(progress)}%</h2>
            <p className='text-sm font-normal max-w-40 text-regular-500'>regulatory documents scanned</p>
          </div>
        </div>

        
          <div className='relative flex items-center '>
            <input
              type='text'
              className='order-solid border-2 border-regular-600 rounded-2xl text-xs py-5 px-4 focus:outline-none bg-primary-200 text-white w-[380px] placeholder:text-[#FFFFFF33] placeholder:font-semibold placeholder:text-xs placeholder:leading-5'
              placeholder='Enter your email to get notification when it ends'
            />
            <AiOutlineSend size={20} color='#FFFFFF66' className='absolute right-[19px]' />
            </div>
      </div>
      <CopyRight color='text-regular-500' />
    </section>
  )
}

export default LoadingYourDashboard
