import React from 'react'
import allRoutes from '../../lib/allRoutes'
import { Link } from 'react-router-dom'

function AvailableLawyersNotification() {
  const notifications = [
    {
      imageUrl:
        'https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      title: 'Your AI Assistant',
      name: 'Here to help you with your enquiries',
      redirect:allRoutes.chatBox
    }
  ]

  return (
    <ul className='flex flex-col g-4 cursor-pointer'>
      {notifications?.map((notification, index) => {
        return (
          <li 
          key={index + notification.title} 
          className={`mb-2 flex items-center space-x-2 capitalize ${index === 0 ? 'cursor-pointer' : ''}`}
          // onClick={(e) => {
          //   e.preventDefault();
          //   // if (index === 0) {
          //   //   window.location.href = notification.redirect;
          //   // }
          // }}
        >
          <Link to={notification.redirect} className='flex items-center'>
            <img src={notification.imageUrl} alt={notification.title} className='rounded-full w-7 h-7 mr-2' />
            <div className='w-40'>
              <h2 className='truncate ... font-normal'>{notification.title}</h2>
              <span className='text-xs text-gray-500 truncate overflow-hidden whitespace-nowrap'>{notification.timestamp}</span>
            </div>
            </Link>
  
          </li>
        )
      })}
    </ul>
  )
}

export default AvailableLawyersNotification
