import React from 'react'
import { useForm } from 'react-hook-form'
import { useParams, useNavigate } from 'react-router-dom'
import { useSetForgotPassword } from '../hooks/useForgotAndSetForgotPassword'
import Input from '../ui/Input'
import { toast } from 'react-toastify'
import { useState } from 'react'

const SetForgotPassword = () => {
  const { iForgotRequestId } = useParams()
  const [showPassword, setShowPassword] = useState(false)
  const { handleSubmit, control } = useForm()
  const navigate = useNavigate()
  const { mutateAsync: setForgotPasswordMutation } = useSetForgotPassword(iForgotRequestId)

  const onSubmit = async (data) => {
    try {
      const response = await setForgotPasswordMutation({ sPassword: data?.sPassword })
      toast.success(response.message || 'Password reset successful.')
      navigate('/')
    } catch (error) {
      toast.error(error.message || 'Failed to reset password.')
    }
  }
  return (
    <div className='flex items-center justify-center min-h-screen bg-gray-100'>
      <div className='bg-white p-8 rounded shadow-md w-full max-w-md'>
        <h2 className='text-xl mb-4'>Set New Password</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Input
              name='sPassword'
              type='password'
              showPassword={showPassword}
              setShowPassword={setShowPassword}
              eye='eye'
              placeholder='Enter New Password'
              control={control}
              rules={{
                required: 'Password is required',
                pattern: {
                  value: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
                  message: 'Use min 6 and max 16 characters with a mix of letters, numbers & symbols.'
                }
              }}
            />
          </div>
          <div className='mt-4'>
            <button type='submit' className='bg-blue-500 text-white px-4 py-2 rounded'>
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default SetForgotPassword
