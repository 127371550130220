import React from "react";
import PropTypes from 'prop-types';
import Markdown from 'react-markdown';

function DetailsTable({ data }) {
  const documentDetails = data || {};

  return (
    <section className="pb-7">
      <div className="bg-gray-100 rounded-2xl p-6 shadow-lg">
        <h2 className="text-2xl font-bold mb-4 text-gray-800">Document Details</h2>
        
        {
          <div key={documentDetails.title} className="bg-white rounded-xl p-4 mb-4 shadow">
            <h3 className="text-xl font-semibold text-gray-800 mb-2">{documentDetails.title}</h3>
                <Markdown>{documentDetails.value}</Markdown>
          </div>
        }
      </div>
    </section>
  );
}

export default DetailsTable;

DetailsTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  )
}
