import React from 'react'
import { CiSearch, CiTimer } from 'react-icons/ci'
import { FaBars } from 'react-icons/fa'
import { GoBell } from 'react-icons/go'
import { LiaFileAlt } from 'react-icons/lia'
import { MdOutlineLightMode } from 'react-icons/md'
import { PiStarThin } from 'react-icons/pi'
import { TbIcons } from 'react-icons/tb'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types';

const Header = ({ toggleSidebar, isSidebarOpen }) => {
  return (
    <header className='bg-primary-600 text-primary-500 p-4 flex justify-between items-center border-b-2 border-primary-300 '>
      <div className='flex items-center space-x-4'>
        {!isSidebarOpen && (
          <button className='text-xl' onClick={toggleSidebar}>
            <FaBars />
          </button>
        )}
        
        <div className='hidden md:flex space-x-4'>
          <button className='p-2 rounded'>
            <LiaFileAlt />
          </button>
          <button className='p-2 rounded'>
            <PiStarThin />
          </button>
        </div>

        <div className='hidden md:flex items-center space-x-4'>
          <Link to={'#'}>Dashboard</Link>
          <span>/</span>
          <Link to={'#'}>Default</Link>
        </div>
      </div>
      <div className='flex items-center space-x-4'>
        <div className='relative flex items-center'>
          <CiSearch className='absolute left-2' />
          <input type='text' placeholder='Search...' className='p-2 pl-8 pr-8 rounded focus:outline-none' />
          {/* <FaEnvelope className='absolute right-2' /> */}
          <TbIcons className='absolute right-2' />
        </div>
        <div className='hidden md:flex space-x-4'>
          <button className='p-2 rounded'>
            <MdOutlineLightMode />
          </button>
          <button className='p-2 rounded'>
            <CiTimer />
          </button>
          <button className='p-2 rounded'>
            <GoBell />
          </button>
          <button className='p-2 rounded'>
            <LiaFileAlt />
          </button>
        </div>
      </div>
    </header>
  )
}

export default Header
Header.propTypes = {
  toggleSidebar: PropTypes.func.isRequired,
  isSidebarOpen: PropTypes.bool.isRequired,
};