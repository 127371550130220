import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const BarChartComponent = () => {
  const labels = ["January", "February", "March", "April", "May", "June"];

  const data = {
    labels: labels,
    datasets: [
      {
        label: "My First Dataset",
        data: [65, 59, 80, 81, 56, 55, 40],
        backgroundColor: ["#A8C5DA"],
        borderColor: ["#A8C5DA"],
        borderWidth: 1,
        barThickness: 30,
        borderRadius: 5,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 4,
          callback: function (value) {
            if (value % 10 === 0 && value <= 60) {
              return value;
            }
          },
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
    },
    layout: {
      padding: {
        top: 20,
      },
    },
  };

  // Plugin to set background color for the x-axis
  const xAxisBgPlugin = {
    id: "xAxisBg",
    beforeDraw: (chart) => {
      const ctx = chart.ctx;
      const xAxis = chart.scales["x"];
      const chartArea = chart.chartArea;
      ctx.save();
      ctx.fillStyle = "#f5f5f5"; 
      const margin = 0;
      ctx.fillRect(
        chartArea.left,
        xAxis.top - margin,
        chartArea.right - chartArea.left,
        xAxis.bottom - xAxis.top + margin
      );
      ctx.restore();
    },
  };

  return (
    <div>
      <Bar data={data} options={options} plugins={[xAxisBgPlugin]} />
    </div>
  );
};

export default BarChartComponent;
