import { useQuery } from '@tanstack/react-query'
import apiClient from '../../lib'
// import { getCurrentUser } from 'api/auth/query'
export const getCurrentUser = () => {
  return apiClient.get('profile')
}

function useUser() {
  const {
    data: user,
    isLoading,
    isError
  } = useQuery({
    queryKey: ['user'],
    queryFn: getCurrentUser,
    staleTime: 5 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
    retry: false
  })

  return { user, isLoading, isError }
}

export default useUser
