import { useState, useEffect } from 'react';
import { getItem, removeItem } from '../utils';

export function useAuth() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!getItem('jwtToken')
  );

  useEffect(() => {
    const token = getItem('jwtToken');
    setIsAuthenticated(!!token);
  }, []); 

  const logout = () => {
    removeItem('jwtToken');
    setIsAuthenticated(false);
  };

  return { isAuthenticated, logout };
}