import React from 'react';

const ThankYou = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
        <h2 className="text-xl mb-4">Thank You</h2>
        <p>We ve sent a password reset link to your email.</p>
        <button className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
          onClick={() => window.location.href = '/forgot-password'}
        >
          Back to Login
        </button>
      </div>
    </div>
  );
};

export default ThankYou;