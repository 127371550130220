import React from 'react'
import PropTypes from 'prop-types'

const CopyRight = ({ color }) => {
  const currentYear = new Date().getFullYear();
  const textColorClass = color ? `${color}` : 'text-primary-500'; 

  return (
    <div className={`${textColorClass} text-4 font-normal leading-5 pb-7 pt-5 mt-auto`}>
      © {currentYear} Sweephy
    </div>
  );
};

export default CopyRight

CopyRight.propTypes = {
  color: PropTypes.string
}