//working but updated
import React from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa'
import { useLocation } from 'react-router-dom'
import allRoutes from '../../lib/allRoutes'

const Input = ({ name, type, placeholder, label, rules, control, showPassword, setShowPassword, eye ,className, ...rest }) => {
  const { pathname } = useLocation()
  return (
    <div className={` ${pathname === allRoutes.login ? 'pb-3' : 'pb-7'}`}>
      {label && <label className='flex justify-start text-sm font-semibold pb-2'>{label}</label>}
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ field, fieldState: { error } }) => {
          return (
            
            <>
            <div className='relative'>
              <input
                className={`${className} w-full border border-primary-100 font-normal text-sm px-[17px] py-[10px] rounded-xl ${eye ? 'pr-10' : ''} ${
                  error ? 'border-red-500' : ''
                }`}
                {...field}
                {...rest}
                type={showPassword ? 'text' : type}
                placeholder={placeholder}
              />
              {eye && (
                <span
                  className='absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer'
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                </span>
              )}
             
            </div>
             {error && <p className={`max-w-[350px]  text-red-500 text-start text-sm`}>{error.message}</p>}
            </>
            
          )
        }}
      />
    </div>
  )
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  rules: PropTypes.object,
  control: PropTypes.object,
  showPassword: PropTypes.bool,
  setShowPassword: PropTypes.func,
  eye: PropTypes.any,
  className:PropTypes.string
}

export default Input
