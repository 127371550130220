import React, { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Header from '../../views/dashboardHeader/Header.jsx'
import NotificationPanel from '../../views/notification/Notification.jsx'
import Sidebar from '../../views/sideBar.jsx/SideBar.jsx'
import { getDashboard } from '../../Query/Dashboard/dashboardQuery.js'
import { useQuery } from '@tanstack/react-query'
import LoadingYourDashboard from '../../views/loadingYourDashboard/LoadingYourDashboard.jsx'
import { toast } from 'react-toastify'

const Dashboard = () => {
  const location = useLocation()
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)
  const [isLoadingOverride, setIsLoadingOverride] = useState(true)

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen)
  }
  const shouldCallDashboardAPI = location.pathname.startsWith('/dashboard')
  const { data, isError, isSuccess, error, isLoading } = useQuery({
    queryKey: ['dashboard'],
    queryFn: () => getDashboard(),
    select: (data) => data?.data?.oData,
    enabled: shouldCallDashboardAPI,
    onSuccess: (response) => {
      console.log('success', response)
      setIsLoadingOverride(false)
      clearTimeout(timeoutId)
    },
    onError: (error) => {
      console.log('error', error)
      setIsLoadingOverride(false)
      clearTimeout(timeoutId)
    }
  })

  const timeoutId = setTimeout(() => {
    setIsLoadingOverride(false)
  }, 300000);


  useEffect(() => {
    if (isSuccess) {
      setIsLoadingOverride(false)
    }

    if (isError || (error && error.response?.status === 504)) {
      setIsLoadingOverride(false)

      if (error?.response?.status === 504) {
        toast.warning('504 Gateway Time-out: Displaying dashboard with available data.')
      } else {
        toast.error(error.message)
      }
    }
  }, [isSuccess, isError, error])

  if (isLoading || isLoadingOverride) {
    return <LoadingYourDashboard />
  }

  console.log('shouldCallDashboardAPI:', shouldCallDashboardAPI)

  console.log('data:', data)

  return (
    <section className='bg-primary-600 w-full flex min-h-screen'>
      {isSidebarOpen && <Sidebar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />}
      <div className='flex-1 flex flex-col'>
        <Header toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />
        <div className='flex-1 flex overflow-auto bg-white'>
          <div className='flex-1 p-6'>
            <Outlet context={data} />
          </div>
        </div>
      </div>
      {/* TODO: Do we really pass the whole data here? */}
      <NotificationPanel NotificationData={data} />
    </section>
  )
}

export default Dashboard
