import React, { useState, useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import RedFlagTable from '../../shared/ui/RedFlagTable'
import LatestNewsTable from '../../shared/ui/LatestNewsTable'
import DetailsTable from '../../shared/ui/DetailsTable'
import { CategoryScale } from 'chart.js'
import Chart from 'chart.js/auto'
import BarChartComponent from '../../shared/ui/BarChart'
import { useFetchMicroDashboardData } from '../../Query/Dashboard/microDashboardQuery'
import NotificationsTable from '../../shared/ui/NotificationsTable'
import { useCompany } from '../../contexts/CompanyContext'
import { useCompanyOnboardData } from '../../Query/Dashboard/companyQuery'

Chart.register(CategoryScale)

const Default = () => { 
  const location = useLocation()
  const isNestedRoute = location.pathname.includes('/dashboard/default/')
  const { selectedCompany } = useCompany()
  const { data: onboardData, isLoading, error } = useCompanyOnboardData(selectedCompany)

  const { data: microDashboardData } = useFetchMicroDashboardData()

  const [displayData, setDisplayData] = useState(null);

  useEffect(() => {
    if (selectedCompany) {
      setDisplayData(onboardData);
    } else {
      setDisplayData(onboardData);
    }
  }, [selectedCompany, onboardData, microDashboardData]);


  if (isLoading) return <div>Loading...</div>
  if (error) return <div>Error loading data</div>

  console.log('Micro Dashboard Data:', microDashboardData?.nScannedDocument)

  

  return (
    <>
      {!isNestedRoute ? (
        <>
          <section className='pb-7'>
            <h2 className='text-sm font-semibold pb-5'>
              {selectedCompany ? `${selectedCompany} Dashboard` : 'Default Dashboard'}
            </h2>
            <div className='flex flex-row gap-7'>
              <div className='basis-1/2'>
                <div className='flex justify-between gap-4 pb-7'>
                  <div className='p-6 bg-secondary-300 rounded-2xl w-full flex flex-col justify-start'>
                    <h2 className='text-sm font-semibold pb-2'>Scanned documents</h2>
                    <div className='flex items-center gap-3'>
                      <h4 className='text-2xl font-semibold leading-8'>{microDashboardData?.nScannedDocument[selectedCompany]}</h4>
                      <p className='text-base font-normal'>Regulation scanned</p>
                    </div>
                  </div>

                  <div className='p-6 bg-primary-600 rounded-2xl w-full'>
                    <h2 className='text-sm font-semibold pb-2'>Latest Fines</h2>
                    <div className='flex items-center gap-3'>
                      <h4 className='text-2xl font-semibold leading-8'>
                        €{displayData?.sLastFines}
                      </h4>
                      <p className='text-base font-normal'> %{microDashboardData?.sLastFinesPercentage}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='basis-1/2 pl-7'>
                <div className='chart-container p-5 bg-primary-600 rounded-2xl w-full'>
                  <h2 style={{ textAlign: 'start' }} className='font-semibold'>
                    Monthly fixed/non-fixed warnings
                  </h2>
                  <BarChartComponent />
                </div>
              </div>
            </div>
          </section>
          <RedFlagTable data={displayData} />
          <LatestNewsTable data={displayData} />
          <NotificationsTable data={displayData} />
        </>
      ) : (
        <Outlet />
      )}
    </>
  )
}

export default Default
