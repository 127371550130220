import apiClient from '../../lib';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

// Fetch companies for user
export const useCompanies = () => {
  return useQuery({
    queryKey: ['companies'],
    queryFn: async () => {
      const response = await apiClient.get('dashboard/get-companies');
      return response.data.oData;
    }
  });
};

// Fetch company onboard data
export const useCompanyOnboardData = (companyName) => {
  return useQuery({
    queryKey: ['companyOnboard', companyName],
    queryFn: async () => {
      const response = await apiClient.get(`dashboard/fetch-dashboard`, {
        params: { companyName }
      });
      return response.data.oData;
    },
    enabled: !!companyName
  });
};

// Add new company mutation
export const useAddCompany = () => {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: async (companyData) => {
      const response = await apiClient.post('/dashboard/create-dashboard', companyData);
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['companies']);
    }
  });
};