import { useMutation } from '@tanstack/react-query'
// import { useMutation } from 'react-query'
import apiClient from '../../lib'
import { encryption } from '../utils'

// export const useForgotPassword = () => {
//   return useMutation((data) => apiClient.post('profile/forgot-password', data))
// }

// export const useSetForgotPassword = (iForgotRequestId) => {
//   return useMutation(async (data) => {
//     const encryptedPassword = encryption(data?.sPassword)
//     return apiClient.put(`profile/set-forgot-password/${iForgotRequestId}`, { sPassword: encryptedPassword })
//   })
// }

async function forgotPassword(data) {
  try {
    const response = await apiClient.post('profile/forgot-password', data)
    return response.data
  } catch (error) {
    throw new Error(error?.response?.data?.message)
  }
}
export const useForgotPassword = () => {
  return useMutation({
    mutationFn: forgotPassword,
    mutationKey: ['forgotPassword']
  })
}

async function setForgotPassword({ iForgotRequestId, passwordData }) {
  console.log('passwordData setForgotPassword', passwordData)
  console.log('iForgotRequestId setForgotPassword', iForgotRequestId)
  try {
    const encryptedPassword = encryption(passwordData?.sPassword)
    // const response = await apiClient.put(`profile/set-forgot-password/${iForgotRequestId}`, { sPassword: encryptedPassword });
    const response = await apiClient.put(`profile/set-forgot-password/${iForgotRequestId}`, {
      sPassword: encryptedPassword
    })
    return response.data
  } catch (error) {
    console.log('error', error)
    throw new Error(`${error?.response?.data?.message}`)
  }
}

export const useSetForgotPassword = (iForgotRequestId) => {
  return useMutation({
    mutationFn: (data) => setForgotPassword({ iForgotRequestId, passwordData: data }),
    mutationKey: ['setForgotPassword', iForgotRequestId]
  })
}
