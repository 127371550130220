import React, { useState, useEffect } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { FaHome, FaAngleRight, FaRegFolder, FaBuilding } from 'react-icons/fa'
import PropTypes from 'prop-types'
import useUser from '../../shared/hooks/useGetProfile'
import userImg from '../../assets/Images/userImg.png'
import vector from '../../assets/Images/icons/Vector.svg';

import { IoDocumentAttachOutline } from 'react-icons/io5'
import { MdOutlineSwitchAccount } from 'react-icons/md'
import { FiShoppingBag } from 'react-icons/fi'
import { GiNewspaper } from 'react-icons/gi'
import { BsCalendar } from 'react-icons/bs'
import { useCompanies } from '../../Query/Dashboard/companyQuery'
import { useCompany } from '../../contexts/CompanyContext'
import { useCompanyOnboardData } from '../../Query/Dashboard/companyQuery'
import { useForm, Controller } from 'react-hook-form';
import { URL_REGX } from '../../shared/constants/constant';
import { useAddCompany } from '../../Query/Dashboard/companyQuery'

const Sidebar = ({ toggleSidebar }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { user, isLoading } = useUser()
  const { selectedCompany, setSelectedCompany, companies, setCompanies } = useCompany()
  const { data: companiesData } = useCompanies()
  const { data: onboardData } = useCompanyOnboardData(selectedCompany)
  const [selectedCompanyName, setSelectedCompanyName] = useState(null)
  const [addLoading, setAddLoading] = useState(false);
  const [sections, setSections] = useState({
    default: false,
    details: false,
    regSegments: false, 
    relevantNews: false,
    saveDocuments: false,
    calendar: false,
    account: false,
    chat: false,
    corporate: false,
    blog: false,
    social: false,
    cvccompanies: false
  })
  const [showAddCompanyModal, setShowAddCompanyModal] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  // Add form related hooks
  const addCompanyMutation = useAddCompany();
  const { handleSubmit, control, formState: { errors }, setError } = useForm();

  const onSubmit = async (data) => {
    const formData = new FormData();
    
    // Add basic company data
    formData.append('company_name', data.companyName);
    formData.append('website_url', data.website);
    formData.append('jurisdiction', data.jurisdiction);

    if (data.files && data.files.length > 0) {
      // Check each file
      for (let i = 0; i < data.files.length; i++) {
        const file = data.files[i];
        if (file.type !== 'application/pdf') {
          setError('file', {
            type: 'fileType',
            message: 'Only PDF files are allowed'
          });
          return;
        }
        formData.append('file', file); 
      }
    }

    try {
      setAddLoading(true);
      await addCompanyMutation.mutateAsync(formData);
      setAddLoading(false);
      setShowAddCompanyModal(false);
      setSelectedFiles([]); // Reset selected files
    } catch (error) {
      setAddLoading(false);
      console.error('Error adding company:', error);
    }
  };

  useEffect(() => {
    if (companiesData?.length) {
      setCompanies(companiesData);
      if (!selectedCompany) {
        setSelectedCompany(companiesData[0]);
        setSelectedCompanyName(companiesData[0]);
      }
    }
  }, [companiesData, selectedCompany, setSelectedCompany, setCompanies]);

  const isActivePath = (path) => {
    return location.pathname.startsWith(path)
  }

  const toggleSection = (sectionId, path) => {
    setSections((prevState) => ({
      ...prevState,
      [sectionId]: !prevState[sectionId]
    }))
    navigate(path)
  }

  const handleCompanyChange = (e) => {
    const value = e.target.value;
    if (value === 'add_new') {
      setShowAddCompanyModal(true);
    } else if (value === '') {
      setSelectedCompany(null);
      setSelectedCompanyName(null);
      navigate('/dashboard/overview');
    } else {
      setSelectedCompany(value);
      setSelectedCompanyName(value);
    }
  };

  return (
    // <div
    //   className={`fixed inset-y-0 left-0 transform ${
    //     isSidebarOpen ? "translate-x-0" : "-translate-x-full"
    //   } transition-transform duration-300 ease-in-out bg-primary-600 w-64`}
    // >
    <aside className='bg-primary-600 text-primary-500 h-full space-y-6 py-7 px-2 border-r-2 border-primary-300 w-[210px]'>
      <div className='flex items-center space-x-2 px-2'>
        <img src={userImg} alt='Profile' className='rounded-full' onClick={toggleSidebar} />
        <span className='text-sm text-primary-500 font-thin leading-5'>
          {isLoading ? 'Loading...' : user?.data?.oData?.sBusinessName || 'Sweephy'}
        </span>
      </div>

      <nav style={{cursor:"pointer"}}>
      <div className="flex items-center gap-2">
        <div className="relative w-[150px]">
          <select
            value={selectedCompanyName || ''}
            onChange={handleCompanyChange}
            className="w-full border border-primary-100 font-normal text-xs px-3 py-1.5 rounded-xl appearance-none bg-white"
          >
            {companiesData?.map((companyName) => (
              <option key={companyName} value={companyName}>
                {companyName}
              </option>
            ))}
            <option value="add_new">+ Add New Company</option>
          </select>
          <img
            className="absolute right-2 top-1/2 transform -translate-y-1/2 w-3 h-3"
            src={vector}
            alt="selectIcon"
          />
        </div>
        <button
          onClick={() => setShowAddCompanyModal(true)}
          className="px-2 py-1 bg-primary-500 text-white text-xs rounded-xl hover:bg-primary-200 transition-colors whitespace-nowrap"
        >
          + Add
        </button>
      </div>
        <NavLink
          to='/dashboard/overview'
          className='block py-1 px-6 rounded transition duration-200 hover:bg-secondary-200  before:before-dot before:text-secondary-200'
        >
          Overview
        </NavLink>
        <NavLink
          to='/dashboard/jurisdiction'
          className='block py-1 px-6 rounded transition duration-200 hover:bg-secondary-200 before:before-dot before:text-secondary-200'
        >
          Jurisdictions
        </NavLink>
        <div className='text-sm font-normal '>
          <h4 className='block py-1 px-6 rounded transition duration-200 text-secondary-200'>Dashboards</h4>

          <div
            className={`py-2.5 px-4 rounded transition duration-200 hover:bg-secondary-200 flex items-center justify-between cursor-pointer ${
              isActivePath('/dashboard/default') ? 'border-l-4 border-black' : ''
            }`}
            onClick={() => navigate('/dashboard/default')}
          >
            <div className='flex items-center'>
              <FaHome className='ml-1' />
              <span className='ml-1'>Default</span>
            </div>
          </div>
          {sections.default && (
            <>
            </>
          )}

          <div
            className={`py-2.5 px-4 rounded transition duration-200 hover:bg-secondary-200 flex items-center justify-between ${
              isActivePath('/dashboard/default/chatbox') ? 'border-l-4 border-black' : ''
            }`}
            onClick={() => toggleSection('chat', '/dashboard/default/chatbox')}
          >
            <div className='flex items-center'>
              <FaAngleRight className={`transition-transform ${sections.chat ? 'rotate-90' : ''}`} fill='gray' />
              <span className='ml-1'>Chat</span>
            </div>
          </div>

          <div
            className={`py-2.5 px-4 rounded transition duration-200 hover:bg-secondary-200 flex items-center justify-between ${
              isActivePath('/dashboard/details') ? 'border-l-4 border-black' : ''
            }`}
            onClick={() => toggleSection('details', '/dashboard/details')}
          >
            <div className='flex items-center'>
              <FaAngleRight className={`transition-transform ${sections.details ? 'rotate-90' : ''}`} fill='gray' />
              <FiShoppingBag className='ml-1' />
              <span className='ml-1'>Details</span>
            </div>
          </div>
          {sections.details && (
            <NavLink to='/dashboard/details' className='block py-2.5 px-6 rounded transition duration-200 hover:bg-secondary-200'>
              Details
            </NavLink>
          )}

          <div
            className={`py-2.5 px-4 rounded transition duration-200 hover:bg-secondary-200 flex items-center justify-between ${
              isActivePath('/dashboard/regsegments') ? 'border-l-4 border-black' : ''
            }`}
            onClick={() => toggleSection('regSegments', '/dashboard/regsegments')}
          >
            <div className='flex items-center'>
              <FaAngleRight className={`transition-transform ${sections.regSegments ? 'rotate-90' : ''}`} fill='gray' />
              <FaRegFolder className='ml-1' />
              <span className='ml-1'>Documents</span>
            </div>
          </div>
          {sections.profile && (
            <NavLink to='/dashboard/regsegments' className='block py-2.5 px-6 rounded transition duration-200 hover:bg-secondary-200'>
              Reg. Segments
            </NavLink>
          )}

          <div
            className={`py-2.5 px-4 rounded transition duration-200 hover:bg-secondary-200 flex items-center justify-between ${
              isActivePath('/dashboard/relevantNews') ? 'border-l-4 border-black' : ''
            }`}
            onClick={() => toggleSection('relevantNews', '/dashboard/relevantNews')}
          >
            <div className='flex items-center'>
              <FaAngleRight className={`transition-transform ${sections.relevantNews ? 'rotate-90' : ''}`} fill='gray' />
              <GiNewspaper className='ml-1' />
              <span className='ml-1'>Relevant News</span>
            </div>
          </div>
          
          
          {/* <div
            className={`py-2.5 px-4 rounded transition duration-200 hover:bg-secondary-200 flex items-center justify-between ${
              isActivePath('/dashboard/cvccompanies') ? 'border-l-4 border-black' : ''
            }`}
            onClick={() => toggleSection('cvccompanies', '/dashboard/cvccompanies')}
          >
            <div className='flex items-center'>
              <FaAngleRight className={`transition-transform ${sections.cvccompanies ? 'rotate-90' : ''}`} fill='gray' />
              <FaBuilding className='ml-1' />
              <span className='ml-1'>CVCcompanies</span>
            </div>
          </div> */}
        </div>

        {/* pages */}
        <div className='pt-7 text-sm font-normal'>
          <h4 className='block py-1 px-6 rounded transition duration-200 text-secondary-200'>Pages</h4>
          <div
            className={`py-2.5 px-4 rounded transition duration-200 hover:bg-secondary-200 flex items-center justify-between ${
              isActivePath('/dashboard/saveDocuments') ? 'border-l-4 border-black' : ''
            }`}
            onClick={() => toggleSection('saveDocuments', '/dashboard/saveDocuments')}
          >
            <div className='flex items-center'>
              <FaAngleRight className={`transition-transform ${sections.saveDocuments ? 'rotate-90' : ''}`} fill='gray' />
              <IoDocumentAttachOutline className='ml-1' />
              <span className='ml-1'>Saved documents</span>
            </div>
          </div>

          {/* {sections.default && (
            <NavLink
              to='/dashboard/saveDocuments'
              className='block py-2.5 px-6 rounded transition duration-200 hover:bg-secondary-200'
            >
              Documents
            </NavLink>
          )} */}

          <div
            className={`py-2.5 px-4 rounded transition duration-200 hover:bg-secondary-200 flex items-center justify-between ${
              isActivePath('/dashboard/calendar') ? 'border-l-4 border-black' : ''
            }`}
            onClick={() => toggleSection('calendar', '/dashboard/calendar')}
          >
            <div className='flex items-center'>
              <FaAngleRight className={`transition-transform ${sections.calendar ? 'rotate-90' : ''}`} fill='gray' />
              <BsCalendar className='ml-1' />
              <span className='ml-1'>Calendar</span>
            </div>
          </div>

          <div
            className={`py-2.5 px-4 rounded transition duration-200 hover:bg-secondary-200 flex items-center justify-between ${
              isActivePath('/dashboard/account') ? 'border-l-4 border-black' : ''
            }`}
            onClick={() => toggleSection('account', '/dashboard/account')}
          >
            <div className='flex items-center'>
              <FaAngleRight className={`transition-transform ${sections.account ? 'rotate-90' : ''}`} fill='gray' />
              <MdOutlineSwitchAccount className='ml-1' />
              <span className='ml-1'>Account</span>
            </div>
          </div>
        </div>
      </nav>

      {showAddCompanyModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-3xl p-6 w-full max-w-2xl">
            <h2 className="text-2xl font-bold mb-4">Add New Company</h2>
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
              {/* Company Name */}
              <div>
                <Controller
                  name="companyName"
                  control={control}
                  rules={{ required: 'Company Name is required' }}
                  render={({ field }) => (
                    <div>
                      <label className="flex justify-start text-sm font-semibold pb-2">Company Name</label>
                      <input
                        {...field}
                        type="text"
                        className="w-full border border-primary-100 font-normal text-sm px-4 py-2 rounded-xl"
                        placeholder="Enter company name"
                      />
                      {errors.companyName && (
                        <p className="text-red-500 text-sm mt-1">{errors.companyName.message}</p>
                      )}
                    </div>
                  )}
                />
              </div>

              {/* Website Link */}
              <div>
                <Controller
                  name="website"
                  control={control}
                  rules={{ 
                    required: 'Website URL is required',
                    pattern: {
                      value: URL_REGX,
                      message: 'Please enter a valid URL'
                    }
                  }}
                  render={({ field }) => (
                    <div>
                      <label className="flex justify-start text-sm font-semibold pb-2">Website URL</label>
                      <input
                        {...field}
                        type="url"
                        className="w-full border border-primary-100 font-normal text-sm px-4 py-2 rounded-xl"
                        placeholder="https://example.com"
                      />
                      {errors.website && (
                        <p className="text-red-500 text-sm mt-1">{errors.website.message}</p>
                      )}
                    </div>
                  )}
                />
              </div>

              {/* Jurisdiction Selection */}
              <div>
                <Controller
                  name="jurisdiction"
                  control={control}
                  rules={{ required: 'Jurisdiction is required' }}
                  render={({ field }) => (
                    <div>
                      <label className="flex justify-start text-sm font-semibold pb-2">Jurisdiction</label>
                      <select
                        {...field}
                        className="w-full border border-primary-100 font-normal text-sm px-4 py-2 rounded-xl"
                      >
                        <option value="">Select jurisdiction</option>
                        <option value="malta">Malta</option>
                        <option value="germany">Germany</option>
                        <option value="baltics">Baltics</option>
                      </select>
                      {errors.jurisdiction && (
                        <p className="text-red-500 text-sm mt-1">{errors.jurisdiction.message}</p>
                      )}
                    </div>
                  )}
                />
              </div>

              {/* Document Upload */}
              <div>
                <Controller
                  name="files"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <div>
                      <label className="flex justify-start text-sm font-semibold pb-2">
                        Upload Documents (PDF only)*
                      </label>
                      <div className="border-2 border-dashed border-primary-100 rounded-xl p-4 text-center">
                        <input
                          type="file"
                          onChange={(e) => {
                            onChange(e.target.files);
                            setSelectedFiles(Array.from(e.target.files));
                          }}
                          className="hidden"
                          id="document-upload"
                          accept=".pdf"
                          multiple // Add multiple attribute
                        />
                        <label 
                          htmlFor="document-upload"
                          className="cursor-pointer text-primary-500 hover:text-primary-600"
                        >
                          {selectedFiles.length > 0 
                            ? `Selected ${selectedFiles.length} file(s): ${selectedFiles.map(f => f.name).join(', ')}` 
                            : 'Click to upload or drag and drop PDF files here'}
                        </label>
                        {errors.files && (
                          <p className="text-red-500 text-sm mt-1">{errors.files.message}</p>
                        )}
                      </div>
                    </div>
                  )}
                />
              </div>

              <div className="flex justify-end gap-2 mt-4">
                <button 
                  type="button"
                  onClick={() => {
                    setShowAddCompanyModal(false);
                    setSelectedFiles([]);
                  }}
                  className="px-4 py-2 rounded-xl text-gray-600 hover:bg-gray-100"
                  disabled={addCompanyMutation.isLoading}
                >
                  Cancel
                </button>
                <button 
                  type="submit"
                  className="px-4 py-2 rounded-xl bg-primary-500 text-white hover:bg-primary-600 disabled:bg-gray-300"
                  disabled={addLoading}
                >
                  {addLoading ? (
                    <div className="flex items-center gap-2">
                      <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
                      Adding...
                    </div>
                  ) : 'Add Company'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </aside>
    // </div>
  )
}
export default Sidebar

Sidebar.propTypes = {
  toggleSidebar: PropTypes.func.isRequired
}
