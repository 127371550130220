import React from 'react'
import AllRoutes from './routes'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// import QueryProvider from './shared/queryClient'
// import { RouterProvider } from 'react-router-dom'
function App() {

  return (
    <div>
      <AllRoutes />
      {/* <RouterProvider router={router} /> */}
      {/* <QueryProvider>
        {(queryClient) => (
          <>
            <RouterProvider router={AllRoutes(queryClient)} />
          </>
        )}
      </QueryProvider> */}
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
    </div>
  )
}

export default App
