import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import vector from '../../assets/Images/icons/Vector.svg';
import { Controller } from 'react-hook-form';
import { URL_REGX } from '../../shared/constants/constant';
import BarChartComponent from '../../shared/ui/BarChart';
import { Line, Pie, Bar } from 'react-chartjs-2';

function CVCcompanies() {
  const { handleSubmit, control, formState: { errors } } = useForm();

  // Mock data for companies
  const companies = [
    { 
      id: 1, 
      name: "Tech Corp Inc.",
      stats: {
        nScannedDocument: 156,
        sLastFines: "134K",
        sLastFinesPercentage: "12",
        redFlags: 8
      }
    },
    { 
      id: 2, 
      name: "Global Solutions Ltd.",
      stats: {
        nScannedDocument: 98,
        sLastFines: "89K",
        sLastFinesPercentage: "8",
        redFlags: 5
      }
    },
  ];

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [showAddCompanyModal, setShowAddCompanyModal] = useState(false);

  // Mock data for overview charts
  const overviewData = {
    complianceScore: {
      labels: companies.map(c => c.name),
      datasets: [{
        label: 'Compliance Score',
        data: [92, 87, 95, 88, 91],
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
      }]
    },
    riskDistribution: {
      labels: ['High Risk', 'Medium Risk', 'Low Risk'],
      datasets: [{
        data: [15, 35, 50],
        backgroundColor: [
          'rgba(255, 99, 132, 0.5)',
          'rgba(255, 206, 86, 0.5)',
          'rgba(75, 192, 192, 0.5)',
        ],
      }]
    },
    regulatoryTrends: {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
      datasets: [{
        label: 'New Regulations',
        data: [12, 19, 15, 17, 22, 24],
        borderColor: 'rgba(54, 162, 235, 1)',
      }]
    }
  };

  const handleCompanyChange = (e) => {
    const value = e.target.value;
    if (value === 'add_new') {
      setShowAddCompanyModal(true);
    } else if (value === '') {
      setSelectedCompany(null);
    } else {
      const company = companies.find(c => c.id === parseInt(value));
      setSelectedCompany(company);
    }
  };

  const onSubmit = (data) => {
    console.log('Form data:', data);
    // Handle form submission including file uploads
    setShowAddCompanyModal(false);
  };

  return (
    <div className="p-6">
      {/* Company Selection Header */}
      <div className="flex items-center gap-4">
        <div className="relative w-64">
          <select
            value={selectedCompany?.id || ''}
            onChange={handleCompanyChange}
            className="w-full border border-primary-100 font-normal text-sm px-4 py-2 rounded-xl appearance-none bg-white"
          >
            <option value="">Overall Dashboard</option>
            {companies.map(company => (
              <option key={company.id} value={company.id}>
                {company.name}
              </option>
            ))}
            <option value="add_new">+ Add New Company</option>
          </select>
          <img
            className="absolute right-3 top-1/2 transform -translate-y-1/2 w-4 h-4"
            src={vector}
            alt="selectIcon"
          />
        </div>
        <button
          onClick={() => setShowAddCompanyModal(true)}
          className="px-4 py-2 bg-primary-500 text-white rounded-xl hover:bg-primary-600 transition-colors"
        >
          + Add Company
        </button>
      </div>

      {!selectedCompany ? (
        // Overview Dashboard
        <div className="space-y-6">
          {/* Top Stats */}
          <div className="grid grid-cols-4 gap-4">
            <div className="bg-white rounded-lg shadow p-4">
              <h3 className="text-sm font-semibold text-gray-600">Total CVC Companies</h3>
              <p className="text-2xl font-bold mt-2">{companies.length}</p>
            </div>
            <div className="bg-white rounded-lg shadow p-4">
              <h3 className="text-sm font-semibold text-gray-600">Average Compliance Score</h3>
              <p className="text-2xl font-bold mt-2">90.6%</p>
            </div>
            <div className="bg-white rounded-lg shadow p-4">
              <h3 className="text-sm font-semibold text-gray-600">Total Red Flags</h3>
              <p className="text-2xl font-bold mt-2">23</p>
            </div>
            <div className="bg-white rounded-lg shadow p-4">
              <h3 className="text-sm font-semibold text-gray-600">Pending Reviews</h3>
              <p className="text-2xl font-bold mt-2">7</p>
            </div>
          </div>

          {/* Charts Grid */}
          <div className="grid grid-cols-2 gap-6">
            <div className="bg-white rounded-lg shadow p-6">
              <h3 className="text-lg font-semibold mb-4">Compliance Scores by Company</h3>
              <div className="h-[300px]">
                <Bar data={overviewData.complianceScore} options={{ maintainAspectRatio: false }} />
              </div>
            </div>
            <div className="bg-white rounded-lg shadow p-6">
              <h3 className="text-lg font-semibold mb-4">Risk Distribution</h3>
              <div className="h-[300px]">
                <Pie data={overviewData.riskDistribution} options={{ maintainAspectRatio: false }} />
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow p-6">
            <h3 className="text-lg font-semibold mb-4">Regulatory Compliance Trends</h3>
            <div className="h-[300px]">
              <Line data={overviewData.regulatoryTrends} options={{ maintainAspectRatio: false }} />
            </div>
          </div>

          {/* Summary Table */}
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <table className="min-w-full">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Company</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Compliance Score</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Risk Level</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Red Flags</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {companies.map(company => (
                  <tr 
                    key={company.id}
                    onClick={() => setSelectedCompany(company)}
                    className="cursor-pointer hover:bg-gray-50 transition-colors"
                  >
                    <td className="px-6 py-4 whitespace-nowrap">{company.name}</td>
                    <td className="px-6 py-4 whitespace-nowrap">90%</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="px-2 py-1 text-xs rounded-full bg-green-100 text-green-800">Low</span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">{company.stats.redFlags}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        // Company-specific dashboard (existing code)
        <div>
          {/* Stats Overview */}
          <div className="flex flex-row gap-7">
            <div className="basis-1/2">
              <div className="flex justify-between gap-4 pb-7">
                <div className="p-6 bg-secondary-300 rounded-2xl w-full flex flex-col justify-start">
                  <h2 className="text-sm font-semibold pb-2">Scanned documents</h2>
                  <div className="flex items-center gap-3">
                    <h4 className="text-2xl font-semibold leading-8">{selectedCompany.stats.nScannedDocument}</h4>
                    <p className="text-base font-normal">Regulation scanned</p>
                  </div>
                </div>

                <div className="p-6 bg-primary-600 rounded-2xl w-full">
                  <h2 className="text-sm font-semibold pb-2">Latest Fines</h2>
                  <div className="flex items-center gap-3">
                    <h4 className="text-2xl font-semibold leading-8">€{selectedCompany.stats.sLastFines}</h4>
                    <p className="text-base font-normal">%{selectedCompany.stats.sLastFinesPercentage}</p>
                  </div>
                </div>
              </div>

              <div className="p-6 bg-primary-600 rounded-2xl w-full">
                <h2 className="text-sm font-semibold pb-2">Red Flags - Needs to be checked</h2>
                <div className="flex justify-between items-center gap-3">
                  <h4 className="text-2xl font-semibold leading-8">{selectedCompany.stats.redFlags}</h4>
                  <p className="text-base font-normal">{selectedCompany.stats.redFlags}</p>
                </div>
              </div>
            </div>

            <div className="basis-1/2 pl-7">
              <div className="chart-container p-5 bg-primary-600 rounded-2xl w-full">
                <h2 style={{ textAlign: 'start' }} className="font-semibold">
                  Monthly fixed/non-fixed warnings
                </h2>
                <BarChartComponent />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Add Company Modal - Simplified */}
      {showAddCompanyModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-3xl p-6 w-full max-w-2xl">
            <h2 className="text-2xl font-bold mb-4">Add New Company</h2>
            <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
              {/* Company Name */}
              <div>
                <Controller
                  name="companyName"
                  control={control}
                  rules={{ required: 'Company Name is required' }}
                  render={({ field }) => (
                    <div>
                      <label className="flex justify-start text-sm font-semibold pb-2">Company Name</label>
                      <input
                        {...field}
                        type="text"
                        className="w-full border border-primary-100 font-normal text-sm px-4 py-2 rounded-xl"
                        placeholder="Enter company name"
                      />
                      {errors.companyName && (
                        <p className="text-red-500 text-sm mt-1">{errors.companyName.message}</p>
                      )}
                    </div>
                  )}
                />
              </div>

              {/* Website Link */}
              <div>
                <Controller
                  name="website"
                  control={control}
                  rules={{ 
                    required: 'Website URL is required',
                    pattern: {
                      value: URL_REGX,
                      message: 'Please enter a valid URL'
                    }
                  }}
                  render={({ field }) => (
                    <div>
                      <label className="flex justify-start text-sm font-semibold pb-2">Website URL</label>
                      <input
                        {...field}
                        type="url"
                        className="w-full border border-primary-100 font-normal text-sm px-4 py-2 rounded-xl"
                        placeholder="https://example.com"
                      />
                      {errors.website && (
                        <p className="text-red-500 text-sm mt-1">{errors.website.message}</p>
                      )}
                    </div>
                  )}
                />
              </div>

              {/* Jurisdiction Selection */}
              <div>
                <Controller
                  name="jurisdiction"
                  control={control}
                  rules={{ required: 'Jurisdiction is required' }}
                  render={({ field }) => (
                    <div>
                      <label className="flex justify-start text-sm font-semibold pb-2">Jurisdiction</label>
                      <select
                        {...field}
                        className="w-full border border-primary-100 font-normal text-sm px-4 py-2 rounded-xl"
                      >
                        <option value="">Select jurisdiction</option>
                        <option value="malta">Malta</option>
                        <option value="germany">Germany</option>
                        <option value="eu">All EU</option>
                      </select>
                      {errors.jurisdiction && (
                        <p className="text-red-500 text-sm mt-1">{errors.jurisdiction.message}</p>
                      )}
                    </div>
                  )}
                />
              </div>

              {/* Document Upload */}
              <div>
                <Controller
                  name="documents"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <div>
                      <label className="flex justify-start text-sm font-semibold pb-2">Upload Documents</label>
                      <div className="border-2 border-dashed border-primary-100 rounded-xl p-4 text-center">
                        <input
                          type="file"
                          multiple
                          onChange={(e) => onChange(e.target.files)}
                          className="hidden"
                          id="document-upload"
                          accept=".pdf,.doc,.docx"
                        />
                        <label 
                          htmlFor="document-upload"
                          className="cursor-pointer text-primary-500 hover:text-primary-600"
                        >
                          Click to upload or drag and drop files here
                        </label>
                      </div>
                    </div>
                  )}
                />
              </div>

              <div className="flex justify-end gap-2 mt-4">
                <button 
                  type="button"
                  onClick={() => setShowAddCompanyModal(false)}
                  className="px-4 py-2 rounded-xl text-gray-600 hover:bg-gray-100"
                >
                  Cancel
                </button>
                <button 
                  type="submit"
                  className="px-4 py-2 rounded-xl bg-primary-500 text-white hover:bg-primary-600"
                >
                  Add Company
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default CVCcompanies;
