import React from "react";
import { useOutletContext } from "react-router-dom";
import PropTypes from 'prop-types'
function LatestNewsTable() {
  const context = useOutletContext();

  const latestNewsData = context?.oNews?.aLatestNews || [];
  console.log("latestNewsData", latestNewsData);

  return (
    <section className="pb-7">
      <div className="flex flex-row w-full">
        <div className="basis-full w-full">
          <div className="p-6 bg-primary-600 rounded-2xl w-full h-full">
            <h2 className="text-sm font-semibold pb-2">Latest news</h2>
            <div className="flex flex-col">
              <div className="text-justify text-xs text-secondary-200 font-normal border-b-2 border-secondary-200 py-2 flex">
                <div className="w-3/6">Title</div>
                <div className="w-1/4 flex items-center">Source</div>
                <div className="w-1/4 flex items-center">Link</div>
              </div>
              {/* <div className="text-xs font-normal flex flex-col py-2">
                {latestNews?.map((news, index) => (
                  <div key={index + news.title} className="py-2 flex">
                    <div className="w-3/4">{news.title}</div>
                    <div className="w-1/4">{news.source}</div>
                    <div
                      className={`w-1/4 before:before-dot before:${news.segmentClass} ${news.segmentClass}`}
                    >
                      {news.segment}
                    </div>
                  </div>
                ))}
              </div> */}
              <div className="text-xs font-normal flex flex-col py-2">
                {latestNewsData?.map((news) => (
                  <div key={news._id} className="py-2 flex">
                    <div className="w-3/6">{news.sTitle}</div>
                    <div className="w-1/4">{news.sSource}</div>
                    <div className="w-1/4">
                      <a 
                        href={news.sLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-400 hover:text-blue-600 hover:underline"
                      >
                        View Article
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="basis-1/4 pl-7">
          <PieChart />
        </div> */}
      </div>
    </section>
  );
}

export default LatestNewsTable;
LatestNewsTable.propTypes = {
  data: PropTypes.any
}