import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useForgotPassword } from '../hooks/useForgotAndSetForgotPassword'
import Input from '../ui/Input'
import { EMAIL_REGX } from '../constants/constant'
import { toast } from 'react-toastify'

const ForgotPassword = () => {
  const { handleSubmit, control } = useForm()
  const [iForgotRequestId, setIForgotRequestId] = useState(null)
  console.log('iForgotRequestId ForgotPassword', iForgotRequestId)
  const navigate = useNavigate()
  const { mutateAsync: forgotPasswordMutation } = useForgotPassword(); 
  
  const onSubmit = async (data) => {
    try {
      const response = await forgotPasswordMutation({ sEmail: data.sEmail })
      console.log("response",response);
      setIForgotRequestId(response.oData?.iForgotRequestId)
      toast.success( response.message || "Reset password request sent successfully!"  , {  autoClose: 500,});
      navigate('/thank-you');
    } catch (response) {
      console.log('response', response)
      toast.error(response.message)
  }
  }
  return (
    <div className='flex items-center justify-center min-h-screen bg-gray-100'>
      <div className='bg-white p-8 rounded shadow-md w-full max-w-md'>
        <h2 className='text-xl mb-4'>Forgot Password</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <Input
              name='sEmail'
              type='email'
              placeholder='Enter your email'
              control={control}
              rules={{
                required: 'Email is required',
                pattern: {
                  value: EMAIL_REGX,
                  message: 'Invalid Email Address'
                }
              }}
            />
          </div>
          <div className='mt-4'>
            <button type='submit' className='bg-blue-500 text-white px-4 py-2 rounded'>
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
export default ForgotPassword