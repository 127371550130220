import React from 'react'
import PropTypes from 'prop-types'
import { MdNotificationsActive } from "react-icons/md";
import ActivitiesNotifications from '../../shared/ui/ActivitiesNotifications'
import AvailableLawyersNotification from '../../shared/ui/AvailableLawyersNotification'
import { timeAgo } from '../../shared/constants/constant'

const NotificationPanel = ({ NotificationData }) => {
  const notifications = NotificationData?.aNotifications || [];

  return (
    <div className='w-72 bg-white shadow-md p-4 border-l-2 border-primary-300 text-sm font-normal overflow-y-scroll'>
      <h2 className='pb-5 font-semibold'>Notifications</h2>
      <ul className='flex flex-col g-2 pb-9 cursor-pointer'>
        {notifications?.map((notification, index) => {
          return (
            <li key={index + notification.sTitle} className='mb-2 flex space-x-2'>
              <span className='text-[25px]'><MdNotificationsActive /></span>
              <div className='w-40'>
                <h2 className='truncate ... font-normal'>{notification.sTitle}</h2>
                <a 
                  href={notification.sEurLink}
                  target="_blank"
                  rel="noopener noreferrer" 
                  className="text-xs text-blue-500 hover:text-blue-700 truncate overflow-hidden whitespace-nowrap"
                >
                  View Document
                </a>
                <span className='text-xs text-gray-500 block truncate overflow-hidden whitespace-nowrap'>
                  {timeAgo(new Date(notification.dPubDate))}
                </span>
              </div>
            </li>
          )
        })}
      </ul>

      <h2 className='pb-5 font-semibold'>Activities (History)</h2>
      <ActivitiesNotifications oResourcesDataForActivity={NotificationData} />

      <h2 className='pb-5 font-semibold'>Available lawyers</h2>
      <AvailableLawyersNotification />
    </div>
  )
}

export default NotificationPanel

NotificationPanel.propTypes = {
  NotificationData: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array])
}
