import React from 'react'

import { timeAgo } from '../constants/constant'
import PropTypes from "prop-types";
import { MdNotificationsActive } from 'react-icons/md';
import { HiNewspaper } from 'react-icons/hi';


function ActivitiesNotifications({ oResourcesDataForActivity }) {
  console.log('oResourcesDataForActivity', oResourcesDataForActivity)
  const notifications = [
    {
      title: 'Spain regulations updated',
      timestamp: new Date(Date.now()),
      icon: <MdNotificationsActive />
    },
    {
      title: 'MiCA additional case upload',
      timestamp: new Date(Date.now() - 5 * 60 * 1000),
      icon: <MdNotificationsActive />
    },
    {
      title: 'ViDA checked and red flag 3',
      timestamp: new Date(Date.now() - 10 * 60 * 1000),
      icon: <MdNotificationsActive />
    },
    {
      title: 'Modified A data in User DB',
      timestamp: new Date(Date.now() - 10 * 60 * 1000),
      icon: <MdNotificationsActive />
    },
    {
      title: 'Deleted a page in Blogs(Spa',
      timestamp: new Date(Date.now() - 10 * 60 * 1000),
      icon: <MdNotificationsActive />
    }
  ]

  // TODO: Fix this oResourcesDataForActivity is an object, not an array for now return const array notifications
  // also the const notifications above should be removed
  const displayNotifications = oResourcesDataForActivity ? oResourcesDataForActivity : notifications;
  // const displayNotifications = notifications;

  console.log('displayNotifications', displayNotifications)


  return (
    <>
      <ul className='flex flex-col g-2 pb-9'>
        {displayNotifications?.oNews?.aLatestNews?.length > 0 ? (
          displayNotifications.oNews.aLatestNews.map((notification, index) => (
            <li key={index + notification.sTitle} className='mb-2'>
              <a
                href={notification.sLink}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-primary-100 transition-colors flex items-center gap-2"
              >
                <HiNewspaper className="text-primary-100" size={16} />
                <h2 className='truncate ... font-normal'>
                  {notification.sTitle}
                </h2>
              </a>
            </li>
          ))
        ) : (
          <li>No notifications available</li>
        )}
      </ul>
    </>
  )
}

export default ActivitiesNotifications;
ActivitiesNotifications.propTypes = {
  oResourcesDataForActivity: PropTypes.object
};