import apiClient from '../../lib'

// export function getDashboard() {
//   return apiClient.get(`dashboard/`)
// }

export async function getDashboard() {
  const response = await apiClient.get('dashboard/');
  return response;
};


export function sendChatResponse(message, iChatHistoryId, selectedCompany) {
  return apiClient.post('chat/response', { 
    sPrompt: message, 
    iChatHistoryId,
    selectedCompany
  }).then(response => {
    return {
      ...response,
      data: {
        ...response.data,
        iChatHistoryId: response.data?.iChatHistoryId || iChatHistoryId
      }
    }
  })
}

export function fetchChatHistory() {
  return apiClient.get('chat/list-chat-history')
}

export function fetchMessages(chatHistoryId) {
  console.log('chartHis', chatHistoryId)
  return apiClient.get(`chat/get-chat-messages/${chatHistoryId}`);
}



