import React from 'react'

function PageNotFound() {
  return (
    <div>
      <h1>PageNotFound</h1>
    </div>
  )
}

export default PageNotFound
