import React from 'react'
import {  Navigate } from 'react-router-dom';
import { useAuth } from '../shared/hooks/useAuthenticationState';
import PropTypes from 'prop-types';
import allRoutes from '../lib/allRoutes';


export const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? children : <Navigate to={allRoutes.login}/>;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired
}

// const Protected = () => {
//   const token = localStorage.getItem("jwtToken");

//   return token ? <Outlet /> : <Navigate to="/login" />;
// };

// export default Protected;
